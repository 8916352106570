
import { useEffect, useState } from 'react'
import images from './content/images'
import axios from 'axios'



//Componenti
import Header from './components/Header'
import Home from './components/Home'
import Info from './components/Info'
import Photos from './components/Photos'
import Immagine from './components/Immagine'
import Music from './components/Music'
import AudioPlayer from './components/AudioPlayer'


const App = () => {

  const [section,setSection] = useState('Home')
  const [brano,setBrano] = useState(null)
  const [audio,setAudio] = useState(null)
  const [statusPlayer,setStatusPlayer] = useState(false)
  const [imageFile,setImageFile] = useState(null)
  const [date,setDate] = useState([])
  const [banner,setBanner] = useState(false)

  const changeSection = (s) => {
    switch(s)
    {
      case 'Info':
      {
        return (<>
          <Header setSection={setSection}/>
          <Info date={date}/>
        </>
        )
      }
      case 'Photo':
      {
        return (<>
          <Immagine src={imageFile} setSrc={setImageFile} />
          <Header setSection={setSection}/>
          <Photos setImage={setImageFile}/>

        </>
        )
      }
      case 'Music':
      {
        return (<>
          <Header setSection={setSection}/>
          <Music brano={brano} setBrano={setBrano}/>
        </>
        )     
       }
      case 'Home':
      {
        return <Home date={date} banner={banner} setBanner={setBanner}/>
      }
      default:
      {
        return <Home date={date} banner={banner} setBanner={setBanner}/>
      }
    }
  }

  const hookGestioneAudio = (brano) => {

    if(audio !== null && audio !== undefined) 
    {
      audio.pause()
      setStatusPlayer(false)
    }
    if(brano !== null)
    {
      const pathUrl = `${brano.file}`
      const audioFile = new Audio(pathUrl)
  
      setAudio(audioFile)
    }
  }

  const hookPlayerAudio = () => {
    if(audio !== null && audio !== undefined) 
    {
      console.log(audio)
      audio.play()
      setStatusPlayer(true)
    }
  }

  const hookGetDate = () => {
    const urlDate = "./media/dati/date.json"
    axios.get(urlDate).then(d => d.data).catch(e => console.log(e)).then(d => {setDate(d.date)})
  }

  useEffect(() => hookGetDate(),[])
  useEffect(() => hookGestioneAudio(brano),[brano])
  useEffect(() => hookPlayerAudio(audio),[audio])

  return (
    <div id="App">
      <div id="content">

        {changeSection(section)}

      </div>
      <div id="menu">
      {statusPlayer === true ? <AudioPlayer statusPlayer={statusPlayer} setStatusPlayer={setStatusPlayer} audio={audio} setBrano={setBrano} brano={brano}/> : null}

          <ul>
            <li><img src={section==='Music'? images.icn_music_on : images.icn_music} alt="Music" onClick={() => setSection('Music')}/></li>
            <li><img src={section==='Photo'? images.icn_photo_on : images.icn_photo} alt="Photos" onClick={() => setSection('Photo')}/></li>
            <li><img src={section==='Info'? images.icn_info_on : images.icn_info} alt="Info" onClick={() => setSection('Info')}/></li>
          </ul>
        </div>
    </div>
  )
}

export default App;
