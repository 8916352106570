import images from "../content/images"

const AudioPlayer = (props) => {

    const handlePause = () => {
        props.audio.pause()
        props.setBrano(null)
        props.setStatusPlayer(false)
    }


    return (
        <div id="audioplayer">
            <img src={images.icn_pause} alt="pause music" onClick={()=>handlePause()}/>
            <div className="infoData">
                <h4>{props.brano.titolo}</h4>
                <p>Autore: {props.brano.autore}</p>
            </div>
        </div>
    )
}

export default AudioPlayer