import { useEffect, useState } from "react"
import axios from "axios"

const Photos = ({setImage}) => {

    const [photoList,setPhotolist] = useState([])
    const urlPhotos = "./media/photos/"

    const hookGetPhotoList = () => {
        const urlPhotoList = "./media/dati/photos.json"
        axios.get(urlPhotoList).then(d => d.data).catch(e => console.log(e)).then(d => setPhotolist(d.photos) )
    }

    useEffect(() => hookGetPhotoList(),[])

    return (
        <div className="Photos">
            <div className="cuscinetto"></div>
            <ul className="listato_flex listato_foto">
                {  
                    photoList.map((e,i) => <li key={i} ><img src={`${urlPhotos}${e.tn}`} alt={`Foto_${i+1}`} className="photo_tmb" onClick={() => setImage(`${urlPhotos}${e.img}`)}/></li>)
                }
            </ul>
         <div className="cuscinetto"></div>
        </div>

    )
}

export default Photos