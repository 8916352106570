import { useEffect, useState } from "react"
import axios from "axios"
import images from "../content/images"

const Music = (props) => {

    const [brani,setBrani] = useState([])
    const urlFilesBrani = "./media/music/"

    const fileBrano = props.brano!==null ? props.brano.file : null

    const hookGetBrani = () => {
        const urlBraniList = "./media/dati/music.json"
        axios.get(urlBraniList).then(d => d.data).catch(e => console.log(e)).then(d => setBrani(d.brani) )
    }

    useEffect(() => hookGetBrani(),[])
    
    return (
        <div className="music">  
            <div className="cuscinetto"></div>
      
            <h2 className="section_title">Cover Songs:</h2>
            <ul className="listato">
                {brani.map(e => {
                    return <li key={e.title} className="listato_flex brano_musicale">
                               <img src={fileBrano===`${urlFilesBrani}${e.file}`? images.icn_music_on : images.icn_music} alt={e.title} className="icn_maps_tag" onClick={() => props.setBrano({
                                file:`${urlFilesBrani}${e.file}`,
                                titolo:e.title,
                                autore:e.autore
                               })}/>
                                <div className="infoData">
                                   <h4>{e.title}</h4>
                                    <p>Autore: {e.autore}</p>
                                </div>
                    </li>
                })}
            </ul>
            <div className="cuscinetto"></div>

            

            
        </div>
    )
}

export default Music