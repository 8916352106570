import images from "../content/images"


const Info = (props) => {

    
    const getDate = () => {

        if(props.date.length === 0) {
            return(                
                <ul className="listato">
                   <li  className="listato_flex dataConcerto">
                       <div className="infoData">
                           <h4 className="dateVuote">Al momento non ci sono date in programma</h4>
                       </div>
                   </li>
               </ul>)
        }  
        else
        {
            return (
                <ul className="listato">
                {   
                    props.date.map((e) => 
                    {
                        return(
                            <li key={e.data} className="listato_flex dataConcerto">
                                <a href={e.indirizzo}><img src={images.mapsTag} alt="Indirizzo del locale" className="icn_maps_tag"/></a>
                                <div className="infoData">
                                    <h4>{e.locale}</h4>
                                    <p>{e.data} - {e.ora}</p>
                                </div>
                            </li>)
                    })
                }
            </ul>)
        }
    }


    return (
        <div className="info">  
            <div className="cuscinetto"></div>
      
            <h2 className="section_title">Prossime Date:</h2>
            {getDate()}

            <div className="recapiti">
                <h2 className="section_title">Info e richieste:</h2>
                <ul className="listaRecapiti">
                    <li className="listato_flex">
                        <a href="mailto:whitestools@gmail.com" ><img src={images.icn_mail_dark} alt="indirizzo mail" className="icn_maps_tag"/></a>
                        <h4><a href="mailto:whitestools@gmail.com" >whitestools@gmail.com</a></h4>
                    </li>
                    <li className="listato_flex">
                        <a href="http://instagram.com/whitestools" ><img src={images.icn_instagram} alt="Instagram" className="icn_maps_tag"/></a>
                        <h4><a href="http://instagram.com/whitestools" >instagram.com/whitestools</a></h4>
                    </li>
                    <li className="listato_flex">
                        <a href="https://www.facebook.com/whitestools/" ><img src={images.icn_facebook} alt="Facebook" className="icn_maps_tag"/></a>
                        <h4><a href="https://www.facebook.com/whitestools/" >facebook.com/whitestools</a></h4>
                    </li>
                </ul>
            </div>

            
        </div>
    )
}


export default Info