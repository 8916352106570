import icn_music from './img/icn_music.png'
import icn_music_on from './img/icn_music_on.png'
import icn_photo from './img/icn_photo.png'
import icn_photo_on from './img/icn_photo_on.png'
import icn_info from './img/icn_info.png'
import icn_info_on from './img/icn_info_on.png'
import mapsTag from "../content/img/mapsTag.png"
import icn_mail from "../content/img/icn_mail_white.png"
import icn_mail_dark from "../content/img/icn_mail.png"
import icn_play from "../content/img/icn_play.png"
import icn_pause from "../content/img/icn_pause.png"
import icn_loader from "../content/img/puff.svg"
import logo from "../content/img/logo.png"
import icn_instagram from "../content/img/icn_instagram.png"
import icn_facebook from "../content/img/icn_facebook.png"


const images = {
    icn_music,
    icn_music_on,
    icn_info,
    icn_info_on,
    icn_photo,
    icn_photo_on,
    mapsTag,
    icn_mail,
    icn_mail_dark,
    icn_play,
    icn_pause,
    icn_loader,
    logo,
    icn_instagram,
    icn_facebook
}

export default images