import images from "../content/images"

const Home = (props) => {


    if(props.date.length !== 0 && props.date[0] !== 0)
    {
        const data = props.date[0].data
        return(
            <div className="home">
                <div className="title_home">
                    <h1>The</h1>
                    <h1>White</h1>
                    <h1>Stools</h1>
                    <img src={images.logo} alt="logo" className="logo" />
                    <h3>Cover Band</h3>
                </div>
                {
                   
                    props.banner===true ? <div id="banner_data"><img src={`./media/img/prossimaData_${data.match(/[0-9]+\/[0-9]+\/[0-9]+/g)[0].replaceAll('/',"")}.png`} alt="data"  onClick={() => props.setBanner(false)} /></div> : null
                }
             </div>
        )
    } else {
        return (
            <div className="home">
                <div className="title_home">
                    <h1>The</h1>
                    <h1>White</h1>
                    <h1>Stools</h1>
                    <img src={images.logo} alt="logo" className="logo" />
                    <h3>Cover Band</h3>
                </div>
             </div>
    
        )
    }
}

export default Home