import images from '../content/images'


const Immagine = ({src,setSrc}) => {


    if(src !== null){
      if(src !== images.icn_loader)
      {
        return (
          <div id="immagine">
              <img src={src} alt="immagine"/>    
            <div id="imgHeader">
               <h6 className='pictureCredit'>Picture by Bb</h6>
              <button onClick={() =>setSrc(null)}>Chiudi</button>
              </div>
          </div>
          )
      }
      else
      {
        return (
          <div id="immagine" className="loader">

            <img src={images.icn_loader} alt="immagine"/>
            <div id="imgHeader"><button onClick={() =>setSrc(null)}>Chiudi</button></div>
          </div>
          )
  
      }
      
    }
    else
    {
      return null
    }
  }

  export default Immagine