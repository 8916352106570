import images from "../content/images"


const Header = ({setSection}) => {


    return (
    <div className="header" id="head">
        <img src={images.logo} className="icn_logo" alt="Cover Band" onClick={() => setSection('Home')}/>
        <h1 className="header_title">The white stools</h1>
    </div>)
}

export default Header